var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"ml-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back")])],1),_c('validation-observer',{ref:"AssetEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.editAsset($event)}}},[(_vm.is_staff)?_c('b-form-group',{attrs:{"label":"Organization","label-for":"Organization"}},[_c('validation-provider',{attrs:{"name":"organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}},_vm._l((_vm.organizations),function(org,o){return _c('option',{key:o,domProps:{"value":org}},[_vm._v(" "+_vm._s(org.name)+" ")])}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.is_staff)?_c('b-form-group',{attrs:{"label":"Owner","label-for":"Owner"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Owner")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"asset_owner_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.users,"state":errors.length > 0 ? false : null},model:{value:(_vm.asset_owner_id),callback:function ($$v) {_vm.asset_owner_id=$$v},expression:"asset_owner_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Target","label-for":"target"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Target")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Target","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"asset_name","state":errors.length > 0 ? false : null,"name":"asset_name"},model:{value:(_vm.asset_name),callback:function ($$v) {_vm.asset_name=$$v},expression:"asset_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Asset Name","label-for":"asset Name"}},[_c('b-form-input',{attrs:{"id":"asset_nickname","name":"asset_nickname"},model:{value:(_vm.asset_nickname),callback:function ($$v) {_vm.asset_nickname=$$v},expression:"asset_nickname"}})],1),_c('b-form-group',{attrs:{"label":"Asset Type","label-for":"Asset Type"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Asset Type")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"asset_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.assetTypes,"state":errors.length > 0 ? false : null},model:{value:(_vm.asset_type),callback:function ($$v) {_vm.asset_type=$$v},expression:"asset_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Asset Exposure"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Asset Exposure")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"asset_group_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.assetGroupTypes,"state":errors.length > 0 ? false : null},model:{value:(_vm.asset_group_type),callback:function ($$v) {_vm.asset_group_type=$$v},expression:"asset_group_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.asset_group_type === 'internal')?_c('b-form-group',{attrs:{"label":"Network Location"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Network Location")]),_c('span',{staticClass:"text-danger font-weight-bold h5",staticStyle:{"margin-left":"3px"}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('v-select',{attrs:{"label":"text","placeholder":"--Select Network Location--","options":_vm.networkLocationOptions,"autocomplete":"","reduce":function (networkLocation) { return networkLocation.value; },"clearable":false},model:{value:(_vm.networkLocation),callback:function ($$v) {_vm.networkLocation=$$v},expression:"networkLocation"}})],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.disable,"size":"sm"}},[(_vm.isloading)?_c('span',[_c('b-spinner',{attrs:{"type":"border","small":""}}),_vm._v(" Please wait")],1):_c('span',[_vm._v("Update")])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }