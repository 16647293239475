<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button class="ml-1" @click="goBack()" size="sm" variant="primary"
        >Back</b-button
      >
    </div>
    <!-- form -->
    <validation-observer ref="AssetEditForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="editAsset">
        <!-- Organization-->
        <b-form-group
          label="Organization"
          v-if="is_staff"
          label-for="Organization"
        >
          <validation-provider
            #default="{ errors }"
            name="organization"
            rules="required"
          >
            <b-form-select
              v-model="organization"
              :state="errors.length > 0 ? false : null"
              disabled
            >
              <option :value="org" v-for="(org, o) in organizations" :key="o">
                {{ org.name }}
              </option>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Owner-->

        <b-form-group label="Owner" v-if="is_staff" label-for="Owner">
          <template #label>
            <span>Owner</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="asset_owner_id"
            rules="required"
          >
            <b-form-select
              v-model="asset_owner_id"
              :options="users"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Asset Name -->
        <b-form-group label="Target" label-for="target">
          <template #label>
            <span>Target</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Target"
            rules="required"
          >
            <b-form-input
              id="asset_name"
              v-model="asset_name"
              :state="errors.length > 0 ? false : null"
              name="asset_name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Asset Nick Name -->
        <b-form-group label="Asset Name" label-for="asset Name">
          <b-form-input
            id="asset_nickname"
            v-model="asset_nickname"
            name="asset_nickname"
          />
        </b-form-group>
        <!-- Asset Type-->
        <b-form-group label="Asset Type" label-for="Asset Type">
          <template #label>
            <span>Asset Type</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="asset_type"
            rules="required"
          >
            <b-form-select
              v-model="asset_type"
              :options="assetTypes"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Asset Exposure">
          <template #label>
            <span>Asset Exposure</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="asset_group_type"
            rules="required"
          >
            <b-form-select
              v-model="asset_group_type"
              :options="assetGroupTypes"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Network Location"
          v-if="asset_group_type === 'internal'"
        >
          <template #label>
            <span>Network Location</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <v-select
            v-model="networkLocation"
            label="text"
            placeholder="--Select Network Location--"
            :options="networkLocationOptions"
            autocomplete
            :reduce="(networkLocation) => networkLocation.value"
            :clearable="false"
          />
        </b-form-group>
        <!-- <b-form-group label="Project" label-for="project" v-if="is_staff">
          <v-select
            v-model="project"
            label="text"
            placeholder="--Select Project--"
            :options="projects"
            @input="loadProject"
            autocomplete
            :reduce="(project) => project.value"
          />
        </b-form-group> -->

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid || disable"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Update</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      asset_name: "",
      asset_nickname: "",
      asset_type: "",
      asset_owner_id: "",
      // organization: "",
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      selectedOrganization: this.$store.state.app.org_id,
      // organization: this.$store.state.app.org_id,
      organizations: this.$store.state.app.workspaces,
      // organizations: [{ value: null, text: "--Select Organization--" }],
      assetTypes: [{ value: null, text: "--Select Asset Type--" }],
      users: [{ value: null, text: "--Select Owner--" }],
      projects: [],
      project: "",
      isloading: false,
      asset_group_type: "",
      assetGroupTypes: [
        { value: null, text: "--Select Asset Exposure--" },
        { value: "internal", text: "Internal" },
        { value: "external", text: "Internet Facing" },
      ],
      networkLocationOptions: [],
      networkLocation: null,
      disable: false,
    };
  },
  watch: {
    asset_group_type: function (newAssetGroupType, oldAssetGroupType) {
      if (newAssetGroupType === "internal") {
        this.loadnetworkLocation();
      }
    },
  },
  created: function () {
    this.load();
    this.loadProject();
  },
  computed: {
    organization() {
      return this.organizations.find(
        (org) => org.tenant_uid === this.$store.state.app.org_id
      );
    },
    selectedOrganizationRefid() {
      if (!this.super_admin) {
        return this.$store.state.app.org_id;
      } else if (this.organization) {
        return this.organization.tenant_uid;
      }
      return null;
    },
    selectedOrganizationId() {
      if (!this.super_admin) {
        return this.$store.state.app.organizationId;
      } else if (this.organization) {
        return this.organization.id;
      }
      return null; // Handle the case when organization is not selected
    },
  },
  methods: {
    load: function () {
      if (this.is_staff) {
        // Load oragnization drop down
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org",
        };
        var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let org = {
              value: res.data[key].org_id,
              text: res.data[key].org_name,
            };
            // console.log(res.data[key].profile.organization)
            self.organizations.push(org);
          });
        });
      }

      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/asset-type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        // console.log(res.data)
        res.data.map(function (value, key) {
          let org = {
            value: res.data[key].asset_type_id,
            text: res.data[key].asset_type_name,
          };
          // console.log(res.data[key].profile.organization)
          self.assetTypes.push(org);
        });
      });

      // Load values for edit
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.$route.params.id +
          "/",
      };
      this.$http(options).then((res) => {
        this.asset_owner_id = res.data.asset_owner_id;
        this.asset_name = res.data.asset_name;
        this.asset_nickname = res.data.asset_nickname;
        this.asset_type = res.data.asset_type;
        this.organization = res.data.org_ref_id;
        this.project = res.data.project_id;
        this.asset_group_type = res.data.asset_grouptype;
        this.networkLocation = res.data.network_location;
        // Load user dropdown based on the organization
        let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
        if (this.is_staff) {
          url = url + "?q_org_id=" + this.selectedOrganizationRefid;
        }
        const u_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: url,
        };
        var self = this;
        this.$http(u_options).then((res) => {
          res.data.map(function (value, key) {
            let user = {
              value: res.data[key].id,
              text: res.data[key].full_name,
            };
            self.users.push(user);
          });
        });
      });
    },
    loadProject: function () {
      this.projects = [];
      let url =
        process.env.VUE_APP_BASEURL +
        "project/project/?org_id=" +
        this.selectedOrganizationRefid;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options).then((res) => {
        res.data.map(function (value, key) {
          let pro = {
            value: res.data[key].project_id,
            text: res.data[key].project_name,
          };
          self.projects.push(pro);
        });
      });
    },
    onOrgChange: function () {
      this.users = [];
      let url = process.env.VUE_APP_BASEURL + "user/users/get-all-users";
      if (this.is_staff) {
        url = url + "?q_org_id=" + this.selectedOrganizationRefid;
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].username,
          };
          self.users.push(user);
        });
      });
    },
    editAsset: function () {
      this.$refs.AssetEditForm.validate().then((success) => {
        this.disable = true;
        this.isloading = true;
        if (success) {
          //call to axios
          if (!this.is_staff)
            this.asset_owner_id = this.$store.state.app.user.id;
          let data = {
            asset_name: this.asset_name,
            asset_nickname: this.asset_nickname,
            asset_type: this.asset_type,
            asset_owner_id: this.asset_owner_id,
            project_id: this.project,
            asset_grouptype: this.asset_group_type,
          };
          if (this.asset_group_type == "internal") {
            data.network_location = this.networkLocation;
          }
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "asset/org-asset/" +
              this.$route.params.id +
              "/",
          };
          var self = this;
          this.$http(options)
            .then((res) => {
              console.log(res);
              if (res.data.asset_id) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Updated Successfully",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
                this.isloading = false;
                this.disable = false;
                this.$router.push({ name: "Assets" });
              }
            })
            .catch((err) => {
              console.log(err);
              this.isloading = false;
              this.disable = false;
            });
        }
      });
    },
    loadnetworkLocation() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/network-location/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.networkLocationOptions = res.data.map((item) => ({
          value: item.id,
          text: item.title,
        }));
        // Find the index of the item with text "DEFAULT"
        const defaultIndex = this.networkLocationOptions.findIndex(
          (item) => item.text === "DEFAULT"
        );

        // If "DEFAULT" is found, set it as the default selection
        // if (defaultIndex !== -1) {
        //   this.networkLocation =
        //     this.networkLocationOptions[defaultIndex].value;
        // } else if (this.networkLocationOptions.length > 0) {
        //   // If "DEFAULT" is not found, fallback to selecting the first item
        //   this.networkLocation = this.networkLocationOptions[0].value;
        // }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>